// theme.ts

import { createTheme } from '@mui/material/styles';
import { CustomThemeOptions } from './typescriptTypes/themeTypes';


const lightTheme = createTheme({
  typography: {
    fontFamily: ['"Plus Jakarta Sans"'].join(","),
  },
  palette: {
    mode: "light",
    primary: {
      main: "#FA8602FF",
    },
    secondary: {
      main: "#533634FF",
    },
    background: {
      promoCode: "#f2f1f6",
      paper: "#f2f1f6",
      box: "#ffffff",
      card: "white",
      addressBox: "#Fdfdfd",
      input: "white",
      provider: "white",
      booking: "#f9f8f8",
      heading: "#e8e8e8f2",
      categories: "white",
      navLink: "black",
      buttonColor: "#533B34FF",
    },
    color: {
      navLink: "#282F39",
      logo: "white",
      catLink: "black",
      secondary: "#575757",
      phone: "#F77A26FF",
      text: "white",
      breadcrum: "#5d5d5d",
      subCatName: "white",
      textColor: "#392B28FF",
      danger: "#CF2D2D",
      categories: "black",
    },
    fonts: {
      h1: "24pt",
      h2: "20pt",
      h3: "16pt",
      h4: "12pt",
    },
    icons: {
      icon: "black",
    },
  },
  
} as CustomThemeOptions);

const darkTheme = createTheme({
  typography: {
    fontFamily: ['"Plus Jakarta Sans"'],
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#FA8602FF",
    },
    secondary: {
      main: "#533634FF",
    },
    background: {
      promoCode: "#3C1C10FF",
      paper: "#321104FF",
      box: "#321904FF",
      addressBox: "#533E34FF",
      card: "#533B34FF",
      input: "#483F3DFF",
      provider: "#45281BFF",
      booking: "#041C32",
      heading: "#041C32",
      categories: "#3D2111FF",
      navLink: "white",
      buttonColor: "#FA6502FF",
    },
    color: {
      navLink: "white",
      logo: "white",
      catLink: "white",
      secondary: "white",
      phone: "white",
      text: "black",
      breadcrum: "#5d5d5d",
      subCatName: "white",
      textColor: "white",
      danger: "#CF2D2D",
      categories: "black",
    },
    fonts: {
      h1: "24pt",
      h2: "20pt",
      h3: "16pt",
      h4: "12pt",
    },
    icons: {
      icon: "white",
    },
  },
  
} as CustomThemeOptions);

export { darkTheme, lightTheme };